
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase';
import { Link } from "react-router-dom";

class ContactUs extends BoundComponentBase {

    render() {
        return (
            <div class="home-contact-us" >
                <h2 id="home_contact_us_heading" contentEditable={this.props.global.editing}
                    onPaste={ContactUs.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.home_contact_us_heading }} />
                <p class="home-contact-us-description" id="home_contact_us_description" contentEditable={this.props.global.editing}
                    onPaste={ContactUs.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.home_contact_us_description }} />

                <p class="centred-button">
                {this.props.global.editing ?
                        <a class="customButtonOrange" id="home_contact_us_button" contentEditable="true" onPaste={ContactUs.cleanse}  onBlur={this.handleEdit}
                            dangerouslySetInnerHTML={{ __html: this.props.global.home_contact_us_button }} /> :
                        <Link class="customButtonOrange" to="/contact-us" dangerouslySetInnerHTML={{ __html: this.props.global.home_contact_us_button }} />
                    }
                </p>
            </div>
        );
    }
}

export default connect(ContactUs.mapStateToProps)(ContactUs);
