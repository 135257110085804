
import ImageBanner from './ImageBanner'
import Description from './Description'  

function Agriculture() {
    return (
        <div class="home-page">
            <ImageBanner />
            <Description />   
        </div>
    );
}

export default Agriculture;
