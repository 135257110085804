import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class ImageChanger extends BoundComponentBase {
    constructor(props) {
        super(props)
        this.state = {
            style: { visibility: "hidden", position: "absolute" }, inputStyle: { display: "none" } };
    }


    changeImage = (event) => {
        if (this.props.global.editing) {
            this.setState({ ...this.state, style: { visibility: "visible", position: "absolute", top: event.pageY + "px", left: event.pageX + "px" }, inputStyle: { display: "initial" } });
        }
    }

    //getBase64 = (file) => {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader();
    //        reader.readAsDataURL(file);
    //        reader.onload = () => resolve(reader.result);
    //        reader.onerror = error => reject(error);
    //    });
    //}


    close = () => {
        this.setState({ ...this.state, style: { visibility: "hidden", position: "absolute" }, inputStyle: { display: "none" } })
    }

    uploadFile = (evt) => {
        const [file] = evt.target.files
        if (file) {
            this.model.image.preview = URL.createObjectURL(file);
            this.model.image.fileType = file.type.split('/')[1];
            this.model.image.file = file;
            //this.model.image.filePath = this.model.image.url.replace(/\.[^/.]+$/, "") + '.' + file.type.split('/')[1];
            this.model.image.filePath = this.model.image.url.replace(/^.*[\\\/]/, '').replace(/\.[^/.]+$/, "") + '.' + file.type.split('/')[1];
            this.pushModel();
        }
        this.close();
    }


           /* 

            <img onClick={this.changeImage}  {...this.props} src={"https://verbank.lu/images/imageBanner.jpg" || this.model.image.displayUrl} />
              */
    render() {
        return (<>

            <img onClick={this.changeImage}  {...this.props} src={this.model.image.preview || this.model.image.displayUrl  } />
            <div onBlur={this.close} style={this.state.style}>
                <form runat="server">
                    <input style={this.state.inputStyle} accept="image/*" onChange={this.uploadFile} type='file' />
                </form>
            </div>
        </>
        );
    }
}

export default connect(ImageChanger.mapStateToProps)(ImageChanger);
