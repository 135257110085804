import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class Footer extends BoundComponentBase {

    render() {
        return (
            <div class="footer"  >
                <p id="footer_text" contentEditable={this.props.global.editing}
                    onPaste={Footer.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.footer_text }} />
            </div>

        );
    }
}

export default connect(Footer.mapStateToProps)(Footer);
