import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Home from './components/home/Home'
import AboutUs from './components/aboutUs/AboutUs'
import ContactUs from './components/contactUs/ContactUs'
import OurTeam from './components/ourTeam/OurTeam'
import BoundComponentBase from './components/base/BoundComponentBase'
import { connect } from "react-redux";
import ScrollToTop from './helpers/ScrollToTop';

import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Agriculture from './components/agriculture/Agriculture'


class App extends BoundComponentBase {
    constructor(props) {
        super(props);

        this.state = { timedOut: false, imagesLoading: true, preLoading: true, minLoading: true }

    }


    waitForImages = (urls, callback) => {
        let loaded = 0;
        urls.forEach((src) => {
            var img = new Image();
            img.onload = () => {
                if (++loaded == urls.length && callback) {
                    callback();
                }
            };
            img.onerror = (err) => {
                console.log(err);
            };
            img.src = src;
        });
    };

    componentDidMount() {


        setTimeout(() => { this.setState({ ...this.state, minLoading: false }) }, 3000);

        this.waitForImages(["/images/largeLogoOrange.jpg", "https://cutewallpaper.org/21/loading-gif-transparent-background/Bee-Hollow-Farm-beekeeping-classes-and-events-near-Schodack-.gif"],
            () => { this.setState({ ...this.state, preLoading: false }); }
        ); 
        setTimeout(() => { this.setState({ ...this.state, timedOut: true }) }, 10000);

    }



    componentDidUpdate() {
          

        if (this.model.global.loaded) {

            var images = this.getImages();
            var displayUrls = [];

            for (var i = 0; i < images.length; i++) {
                var image = images[i]; 
                displayUrls.push(image.displayUrl);
            }


            this.waitForImages(displayUrls,
                () => { 
                    this.setState({ ...this.state, imagesLoading: false });
                }
            );


        }

    }



    render() {
         // if (true) {
      if (!this.state.timedOut && (this.state.minLoading || this.state.imagesLoading)) {
            return <>
                <div class="backing" />
                <div style={{ visibility: this.state.preLoading ? "hidden" : "visible" }} class="loading">
                <img class="logo" src="/images/largeLogoOrange.jpg" />
                <img class="spinner" src="https://cutewallpaper.org/21/loading-gif-transparent-background/Bee-Hollow-Farm-beekeeping-classes-and-events-near-Schodack-.gif" />
            </div>
                </>
        }
        return (
            <Router>
                <div class="page-layout">
                    <Header />
                    <TransitionGroup>
                        <CSSTransition
                            timeout="300"
                            classNames="fade">
                            <ScrollToTop>
                                <Switch>
                                    <Route path="/advisors">
                                        <AboutUs />
                                    </Route>
                                    <Route path="/agriculture">
                                        <Agriculture />
                                    </Route>
                                    <Route path="/our-team">
                                        <OurTeam />
                                    </Route>
                                    <Route path="/contact-us">
                                        <ContactUs />
                                    </Route>
                                    <Route path="/">
                                        <Home />
                                    </Route>
                                </Switch>
                            </ScrollToTop>
                        </CSSTransition>
                    </TransitionGroup>
                    <Footer />
                </div>
            </Router >
        );
    }
}

export default connect(App.mapStateToProps)(App);