
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import TeamMemberPhoto from '../generic/TeamMemberPhoto'

class OurTeamItem extends BoundComponentBase {
    constructor(props) {
        super(props) 
    }
     

    render() {
        return (
            <div class="home-our-team-item">
                <TeamMemberPhoto  employee={this.model.employee} />
                <p id="employee.name" contentEditable={this.props.global.editing}
                    onPaste={OurTeamItem.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.model.employee.name }} />
                {(this.model.employee.linkedInUrl) && <a href={this.model.employee.linkedInUrl} target="_blank" rel="noopener">
                    <img src="/images/linkedInLink.jpg" alt="Grey LinkedIn Icon" />
                </a>}
            </div >
        );
    }

}

export default connect(OurTeamItem.mapStateToProps)(OurTeamItem);

