import { Link } from "react-router-dom";
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import app from "../../dataStore/firebaseStorage";
import EnvironmentSetting from "../../dataStore/environmentSetting";
import ImageChanger from "../generic/ImageChanger";
//import { getAuth, signInWithPopup,   } from "firebase/auth";

class HeaderItem extends BoundComponentBase {
    constructor(props) {
        super(props);


        this.handleChange = this.handleChange.bind(this);
        this.state = { saving: false };

        this.style = { position: props?.backing ? "static" : "fixed" };
        this.menuItems = [
            {
                title: "Home",
                url: "/"
            },
            {
                title: "Advisors",
                url: "/advisors"
            },
            {
                title: "Agriculture",
                url: "/agriculture"
            },
            {
                title: "Our Team",
                url: "/our-team"
            },
            {
                title: "Contact Us",
                url: "/contact-us"
            },
        ];
    }


    handleChange(event) {
        this.setState({ ...this.state, [event.target.name]: event.target.value });
    }

    publish = () => {

        this.setState({ ...this.state, saving: true });
        app.auth().signInWithEmailAndPassword(EnvironmentSetting().user(this.state?.username), EnvironmentSetting().pxw(this.state?.password))
            .then((result) => {



                var images = this.getImages();

                for (var i = 0; i < images.length; i++) {
                    var image = images[i];

                     
                                         
                    if (image.file) { 
                        app.storage().ref(image.filePath).put(image.file, image.filePath, { type: image.file.type })
                            .then(() => {
                            });

                        image.url = EnvironmentSetting().bucketUrl + image.filePath;
                        image.preview = '';
                        image.filePath = '';
                        image.file = '';
                        image.fileType = '';
                    } 
                }

                this.pushModel();

                var a = this.model.global;

                app.storage().ref(`/siteContent.json`).put(new File([JSON.stringify(this.model.global)], 'siteContent.json', { type: "text/plain" }), { type: "text/plain" })
                    .then(p => {

                        this.props.global.editing = false;
                        this.props.global.editingMode = false;
                        this.pushModel();
                        alert("Site Updated");

                        window.location = '/';
                    })
                    .catch(p => {
                        this.setState({ ...this.state, password: "" });
                        alert("Update Failed");

                        this.setState({ ...this.state, saving: false });
                    });
                 
            })
            .catch(p => {
                this.setState({ ...this.state, password: "" });
                alert("Login Failed");
                this.setState({ ...this.state, saving: false });
            });
         
    }
      

    render() {
        return (
            <div className={this.props.class + ' header'} style={this.style}>

                <div class="header_item_logo">
                    {this.props.global.editing ? 
                        <ImageChanger image={this.model.global.small_logo} class="logo_standard" />
                         :
                        <Link to="/">
                            <img src={this.model.global.small_logo.displayUrl}  class="logo_standard" />
                        </Link>
                    }
                    
                </div>
                <div class="header-menu">

                    <ul>
                        {this.menuItems.map((item) => (
                            <li>
                                <Link to={item.url}>{item.title}</Link>
                            </li>
                        ))}
                        <li class="header-social-media">
                            <a target="_blank" rel="noopener" href="https://www.facebook.com/VerbankAdvisors/">
                                <img src="/images/facebook.jpg" />
                            </a>
                            <a target="_blank" rel="noopener" href="https://twitter.com/Verbank_">
                                <img src="/images/twitter.jpg" />
                            </a>
                            <a target="_blank" rel="noopener" href="https://www.linkedin.com/company/verbankadvisors">
                                <img src="/images/linkedIn.jpg" />
                            </a>
                            <a target="_blank" rel="noopener" href="https://www.instagram.com/verbankadvisors/">
                                <img src="/images/instagram.jpg" />
                            </a>
                        </li>
                        {this.props.global.editingMode &&
                            <li>
                                {!EnvironmentSetting().isDev &&
                                    <>
                                        <input name="username" placeholder="Username" onChange={this.handleChange} />
                                <input name="password" placeholder="Password" type="password" onChange={this.handleChange} value={this.state.password} />
                                </>}
                            <button onClick={this.publish} disabled={this.state.saving} >Publish</button >
                            {this.state.saving && <img class="publish-spinner" src="https://cutewallpaper.org/21/loading-gif-transparent-background/Bee-Hollow-Farm-beekeeping-classes-and-events-near-Schodack-.gif" />}
                            
                            <button onClick={function () { window.location = '/'; }} disabled={this.state.saving}  >Cancel</button ></li>
                        }    </ul>
                </div>
                <div style={{ clear: "both" }}> </div>
            </div>
        );
    }
}

export default connect(HeaderItem.mapStateToProps)(HeaderItem);