
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class ContactUs extends BoundComponentBase {

    render() {
        return (
            <div class="contact-us" >
                <h2 id="contact_us_header" contentEditable={this.props.global.editing}
                    onPaste={ContactUs.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.contact_us_header }} />
                <p id="contact_us_description" contentEditable={this.props.global.editing}
                    onPaste={ContactUs.cleanse}  onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.global.contact_us_description }}
                    class="contact-us-description" />

            </div>
        );
    }
}

export default connect(ContactUs.mapStateToProps)(ContactUs);
