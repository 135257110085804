
export default function EnvironmentSetting() {


    if (window.document.domain == 'verbanknetlive.web.app' ||
        window.document.domain == 'verbank.net') {
        return {
            firebaseConfig: {
                apiKey: "AIzaSyBCSn6Aw_moJgCuTQelCRcGopcjQoFi9Ac",
                authDomain: "verbanknetlive.firebaseapp.com",
                projectId: "verbanknetlive",
                storageBucket: "verbanknetlive.appspot.com",
                messagingSenderId: "349175271202",
                appId: "1:349175271202:web:b3fb5632ce99ef7a9d5f7b",
                measurementId: "G-R9NVB1K8F8",
            },
            bucketUrl: "https://storage.googleapis.com/verbanknetlive.appspot.com/",
            siteMapUrl: "https://storage.googleapis.com/verbanknetlive.appspot.com/siteContent.json",
            pxw: function (pxw) { return pxw },
            user: function (user) { return user },
            isDev: false,
        }
    }


    return {

        firebaseConfig: {
            apiKey: "AIzaSyABvJ9eB4Pp3DtTla9pIjBD4b-bGmRxNXE",
            authDomain: "verbanknetdev.firebaseapp.com",
            projectId: "verbanknetdev",
            storageBucket: "verbanknetdev.appspot.com",
            messagingSenderId: "104788406978",
            appId: "1:104788406978:web:b549e07233e8f61a9d415b",
            measurementId: "G-MJR8BTCW3K"
        },
        bucketUrl: "https://storage.googleapis.com/verbanknetdev.appspot.com/",
        siteMapUrl: "https://storage.googleapis.com/verbanknetdev.appspot.com/siteContent.json",
        pxw: function (pxw) { return "admin1";  },
        user: function (user) { return "ad@min.com";},
        isDev: true,
    }






}

