
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import ImageChanger from "../generic/ImageChanger";

class ImageBanner extends BoundComponentBase {

    render() {
        
        return (
            <div class="agriculture-image-banner">
                <ImageChanger image={this.model.global.agriculture_image_banner_image } alt=""/>
                <div class="title" ><span id="agriculture_banner_message" onPaste={ImageBanner.cleanse} contentEditable={this.props.global.editing}   onBlur={this.handleEdit} class="blue"
                    dangerouslySetInnerHTML={{ __html: this.props.global.agriculture_banner_message }} /></div>
            </div>
        );
    }
}

export default connect(ImageBanner.mapStateToProps)(ImageBanner); 
