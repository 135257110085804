
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BoundComponentBase from '../base/BoundComponentBase'


class Description extends BoundComponentBase {
    render() {
        return (
            <div class="home-description" >
                <p id="agriculture_description_para_1" contentEditable={this.props.global.editing}
                    onPaste={Description.cleanse}  onBlur={this.handleEdit} class="home-description-occupy-niche"
                    dangerouslySetInnerHTML={{ __html: this.props.global.agriculture_description_para_1 }} />
                <p class="centred-button">
                    {this.props.global.editing ?
                        <a class="customButtonOrange" id="agriculture_advisors_button" contentEditable="true" onPaste={Description.cleanse} onBlur={this.handleEdit}
                            dangerouslySetInnerHTML={{ __html: this.props.global.agriculture_advisors_button }} /> :
                        <Link class="customButtonOrange" to="/our-team" dangerouslySetInnerHTML={{ __html: this.props.global.agriculture_advisors_button }} />
                    } 
                </p>
            </div>
        );
    }
}
export default connect(Description.mapStateToProps)(Description);