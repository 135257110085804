import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

class BoundComponentBase extends Component {
    constructor(props) {
        super(props)

        this.model = this.clone(this.props);
        this.model = this.clone(this.getModelObjects(this.props));
        this.getBoundProperties(this.model);
    }


    componentWillReceiveProps(newProps) {
        this.model.global = this.clone(newProps.global);
        this.getBoundProperties(this.model);

    }

    static mapStateToProps = (state) => {
        return {
            global: state,
        };
    };


    getModelObjects = (data) => {

        var response = {};

        for (var key in data) {
            if (key == "global"
                || (data[key] && data[key].constructor === ({}).constructor
                    && data[key].___id)) {
                response[key] = data[key];
            }
        }

        return response;
    }

    getObjectFromGlobalProperties = (id, object) => {

        if (Array.isArray(object)) {
            for (var i = 0; i < object.length; i++) {
                var result = this.getObjectFromGlobalProperties(id, object[i]);
                if (result) {
                    return result
                }
            }
        }
        else if (object.constructor === ({}).constructor) {

            if (object.___id == id) {
                return object;
            }

            for (var key in object) {
                var result = this.getObjectFromGlobalProperties(id, object[key]);
                if (result) {
                    return result
                }
            }
        }

        return null;
    }

    getImages = (data = this.model.global) => {
       return BoundComponentBase.getImagesFromData(data);
    }

    static getImagesFromData = (object) => {

        var results = [];

        if (!object) {
            return results;
        }


        if (Array.isArray(object)) {
            for (var i = 0; i < object.length; i++) {
                var result = BoundComponentBase.getImagesFromData(object[i]);
                if (result.length > 0) {
                    results = [...results, ...result];
                }
            }
        }
        else if (object.constructor === ({}).constructor) {

            if (object.isImage) {
                return [object];
            }
            else {
                for (var key in object) {
                    var result = BoundComponentBase.getImagesFromData(object[key]);
                    if (result.length > 0) {
                        results = [...results, ...result];
                    }
                }
            }
        }

        return results;


    }

    getBoundProperties = (data) => {

        for (var key in data) {
            if (key != "global"
                && data[key].constructor === ({}).constructor
                && data[key].___id) {
                var result = this.getObjectFromGlobalProperties(data[key].___id, this.model.global);
                if (result) {
                    data[key] = result;
                }
            }
        }
    }




    static cleanse = (e) => {
        e.preventDefault();
        var text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    }

    clone = (data) => {
        return _.cloneDeep(data);
    }



    handleEdit = (e) => {
        const { innerHTML, id } = e.target;

        if (id.includes(".")) {
            this.model[id.split(".")[0]][id.split(".")[1]] = innerHTML;
        }
        else {
            this.model.global[id] = innerHTML;
        }
        this.pushModel();
    }

    setProperty = (key, value) => {
        this.props.global[key] = value;
        this.pushModel();
    }


    pushModel() {

        this.props.dispatch({
            type: "UPSERT",
            payload: this.clone(this.model.global),
        });
    }
}
export default BoundComponentBase;