import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import ImageChanger from "./ImageChanger";

class TeamMemberPhoto extends BoundComponentBase {
    constructor(props) {
        super(props) 
        this.employeeImageUrlRef = "home_our_team_" + this.props.employeeId + "_image_url"; 
    }

    update = (url) => { this.model.employee.imageUrl = url; this.pushModel() }


    render() {
        return (
            <div class="team-member-photo" >
                <ImageChanger loading="lazy" image={this.model.employee.image} width="200" height="200" update={this.update} />
                <img loading="lazy" src={this.model.employee.imageUrl} width="200" height="200" />
            </div>
        );
    }
}

export default connect(TeamMemberPhoto.mapStateToProps)(TeamMemberPhoto);
