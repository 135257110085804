
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import ImageChanger from "../generic/ImageChanger";

class AboutUsItem extends BoundComponentBase {
    constructor(props) {
        super(props)
        
    }

    render() {  
        return (    
            <div class="home-about-us-item">
                <img src="/images/imageBanner.jpg" alt="" src={this.props.aboutUs.imageUrl} /> 
                <span class="home-about-us-item-header" id="aboutUs.title" contentEditable={this.props.global.editing}
                    onPaste={AboutUsItem.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.aboutUs.title }}/>
                <p class="home-about-us-item-content" id="aboutUs.description" contentEditable={this.props.global.editing}
                    onPaste={AboutUsItem.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.aboutUs.description }} />
            </div>
        );
    }
}

export default connect(AboutUsItem.mapStateToProps)(AboutUsItem);
