import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore } from "redux";
import { Provider } from "react-redux";
import contentReducer from './dataStore/contentReducer';
import GetContent from './dataStore/getContent';
import BoundComponentBase from './components/base/BoundComponentBase';

 





const store = createStore(contentReducer);
console.log(new Date().getSeconds());

var contentLoading = true;

const waitForImages = (urls, callback) => {
    let loaded = 0;
    urls.forEach((src) => {
        var img = new Image();
        img.onload = () => {
            if (++loaded == urls.length && callback) {
                callback();
            }
        };
        img.onerror = (err) => {
            console.log(err);
        };
        img.src = src;
    });
};


GetContent(function (data) {
    data.editingMode = false;
    data.contentLoading = false;
    data.loaded = true;

    if (window.location.pathname == "/edit") {
        data.editing = true;
        data.editingMode = true;
    }


    var images = BoundComponentBase.getImagesFromData(data);
     

    for (var i = 0; i < images.length; i++) {
        var image = images[i];
        image.displayUrl = image.url + '?a=' + Math.random(); 
    }


    store.dispatch({
        type: "UPSERT",
        payload: data
    });
     
     
     
     
});


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App contentLoading={contentLoading} />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


