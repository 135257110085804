

import EnvironmentSetting from './environmentSetting';

export default function GetContent(success) {

    fetch(EnvironmentSetting().siteMapUrl + '?a=' + Math.random(), { mode: 'cors' }
    )
        .then(function (response) {

            response.text().then(function (text) {

                var data = JSON.parse(text);

                //data.employees[0].image = JSON.parse(JSON.stringify({ isImage: true, url: "https://storage.googleapis.com/verbanknetdev.appspot.com/jorge.png", preview: "", blob: "", filePath: "", fileType: "" }));
                //data.employees[1].image = JSON.parse(JSON.stringify({ isImage: true, url: "https://storage.googleapis.com/verbanknetdev.appspot.com/jorge.png", preview: "", blob: "", filePath: "", fileType: ""  }));
                //data.employees[2].image = JSON.parse(JSON.stringify({ isImage: true, url: "https://storage.googleapis.com/verbanknetdev.appspot.com/jorge.png", preview: "", blob: "", filePath: "", fileType: ""  }));
                //data.employees[3].image = JSON.parse(JSON.stringify({ isImage: true, url: "https://storage.googleapis.com/verbanknetdev.appspot.com/jorge.png", preview: "", blob: "", filePath: "", fileType: ""  }));
                //data.employees[4].image = JSON.parse(JSON.stringify({ isImage: true, url: "https://storage.googleapis.com/verbanknetdev.appspot.com/jorge.png", preview: "", blob: "", filePath: "", fileType: ""  }));
                //data.employees[5].image = JSON.parse(JSON.stringify({ isImage: true, url: "https://storage.googleapis.com/verbanknetdev.appspot.com/jorge.png", preview: "", blob: "", filePath: "", fileType: ""  }));

                //data.employees[1].linkedInUrl = "";
                //data.employees[2].linkedInUrl = "";
                //data.employees[3].linkedInUrl = "https://www.linkedin.com/in/nirav-shah-8047b7/";
                //data.employees[4].linkedInUrl = "";
                //data.employees[5].linkedInUrl = "https://www.linkedin.com/in/mar%C3%ADa-beatriz-mart%C3%ADnez-wagener-23ab61127/";
                

                data.editing = false;

                var objectIndex = 1;

                addIdToObjet(data)


                function addIdToObjet(object) {


                    if (Array.isArray(object)) {
                        for (var i = 0; i < object.length; i++) {
                            addIdToObjet(object[i]);
                        }
                    }
                    else if (object.constructor === ({}).constructor) {

                        for (var key in object) {
                            addIdToObjet(object[key])
                        }

                        object.___id = objectIndex;
                        objectIndex++;
                    }
                }



                success(data);
            });
        }).catch(function (err) {
            //alert(err);
        });





}

