import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import HeaderItem from './HeaderItem'  

class Header extends BoundComponentBase {

    render() {
        return (
            <>
                <HeaderItem class="fixed-header"/>
                <HeaderItem backing="true" />
            </>
        );
    }
}

export default connect(Header.mapStateToProps)(Header);
