
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'

class WhatWeDoItem extends BoundComponentBase {
    constructor(props) {
        super(props)
        this.whatWeDoTitle = "home_what_we_do_" + this.props.itemId + "_title";
        this.whatWeDoList = "home_what_we_do_" + this.props.itemId + "_list";
    }

    render() {
        return (
            <div class="home-what-we-do-item">
                <h3 contentEditable={this.props.global.editing} id="whatWeDo.title" contentEditable={this.props.global.editing}
                    onPaste={WhatWeDoItem.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.whatWeDo.title }} />
                <ul contentEditable={this.props.global.editing} id="whatWeDo.list" contentEditable={this.props.global.editing}
                    onPaste={WhatWeDoItem.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.whatWeDo.list }} />
               
            </div> 

        );
    }
}

export default connect(WhatWeDoItem.mapStateToProps)(WhatWeDoItem);
