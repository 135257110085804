import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase';
import { Link } from "react-router-dom";
import ImageChanger from "../generic/ImageChanger";

class AboutUs extends BoundComponentBase {

    render() {
        return (
            <div class="about-us" >
                <div class="left">
                    <p id="about_us_para_1" contentEditable={this.props.global.editing} onPaste={AboutUs.cleanse}  onBlur={this.handleEdit} class="blue"
                        dangerouslySetInnerHTML={{ __html: this.props.global.about_us_para_1 }} />
                    <p id="about_us_para_2" contentEditable={this.props.global.editing} onPaste={AboutUs.cleanse}  onBlur={this.handleEdit}
                        dangerouslySetInnerHTML={{ __html: this.props.global.about_us_para_2 }} />

                    <p class="centred-button">
                        {this.props.global.editing ?
                            <a class="customButtonOrange" id="our_team_button" contentEditable="true" onPaste={AboutUs.cleanse}  onBlur={this.handleEdit}
                                dangerouslySetInnerHTML={{ __html: this.props.global.our_team_button }} /> :
                            <Link class="customButtonOrange" to="/agriculture" dangerouslySetInnerHTML={{ __html: this.props.global.our_team_button }} />
                        }

                    </p>
                </div>
                <ImageChanger image={this.model.global.about_us_image } alt="Verbank Securities 1.png" />
                <div style={{ clear: "both" }}></div>

            </div>
        );
    }
}

export default connect(AboutUs.mapStateToProps)(AboutUs);
