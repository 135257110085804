export default function counterReducer(state = {
    editing: false,
    contentLoading: true,
}, action) {
    switch (action.type) {
        case "UPSERT":
            state = { ...state, ...action.payload   };
            return state;
        default:
            return state;
    }
}