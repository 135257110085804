
import { connect } from "react-redux";
import BoundComponentBase from '../base/BoundComponentBase'
import TeamMemberPhoto from '../generic/TeamMemberPhoto'

class OurTeamItem extends BoundComponentBase {
    constructor(props) {
        super(props) 
    }
     

    render() {
        return (
            <div class="our-team-item">
                <TeamMemberPhoto  employee={this.model.employee}/>
                <p class="name" id="employee.name" contentEditable={this.props.global.editing}
                    onPaste={OurTeamItem.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.employee.name }} />
                <p class="description" id="employee.description" contentEditable={this.props.global.editing}
                    onPaste={OurTeamItem.cleanse} onBlur={this.handleEdit} dangerouslySetInnerHTML={{ __html: this.props.employee.description }} />
            </div >
        );
    }

}

export default connect(OurTeamItem.mapStateToProps)(OurTeamItem);

